// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'src/app/currency/currency.service';

@Pipe({
  name: 'currencyConverter',
})
export class CurrencyConverterPipe implements PipeTransform {
  constructor(
    private currencyService: CurrencyService,
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(
    value: number,
    direction: string = 'to',
    showSymbol: boolean = true,
    toFixed: number = 4,
  ): string | number | null {
    if (value == null) {
      return '-';
    }

    if (isNaN(value) || value === Infinity) {
      return null;
    }

    let convertedValue: number;

    if (direction === 'to') {
      convertedValue = value / this.currencyService.selectedCurrency.exchangeRate;
    } else if (direction === 'from') {
      convertedValue = value * this.currencyService.selectedCurrency.exchangeRate;
    } else if (direction === 'none') {
      // Use this if you just want the value to have the currency symbol applied
      convertedValue = value;
    } else {
      throw new Error(`Invalid direction (${direction})`);
    }

    if (showSymbol) {
      return this.currencyPipe.transform(
        convertedValue,
        this.currencyService.selectedCurrency.short,
        'symbol-narrow',
        '1.0-0',
      );
    } else {
      return convertedValue.toFixed(toFixed);
    }
  }
}

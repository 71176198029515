import { deepEqual } from '../shared/util';

const selectors = ['body', 'mat-dialog-container', 'div.modal'];

export const matchesTag = (event: Event) =>
  event.target instanceof Element && selectors.some(s => (event.target as Element).matches(s));

export const matchesKey = (event: Event) => event instanceof KeyboardEvent && event.code === 'Backquote';

export const matches = (event: Event) => matchesKey(event) && matchesTag(event);

const konamiCode = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'KeyA',
  'KeyB',
];

export const accumulateCheatCode = (acc: string[], code: string) => [...acc.slice(-(konamiCode.length - 1)), code];
export const matchesCheatCode = (sequence: string[]): boolean => deepEqual(konamiCode, sequence);

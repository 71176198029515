import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs';
import { sortBy } from '../shared/util';
import { FeatureToggles, featureToggles } from './feature-toggles';
import { FeatureToggleService } from './feature-toggles.service';
import { matchesKey } from './feature-toggles.util';

@Component({
  selector: 'app-feature-toggles-dialog',
  styleUrls: ['./feature-toggles-dialog.component.scss'],
  templateUrl: './feature-toggles-dialog.component.html',
})
export class FeatureTogglesDialogComponent {
  readonly toggles$ = this.featureToggleService.toggles$.pipe(
    map(toggles => Object.entries(toggles) as [keyof FeatureToggles, boolean][]),
    map(toggles => toggles.map(([key, value]) => ({ key, value, changed: value !== featureToggles[key] }))),
    map(toggles => sortBy(toggles, 'key')),
  );

  constructor(
    readonly dialogRef: MatDialogRef<FeatureTogglesDialogComponent>,
    readonly featureToggleService: FeatureToggleService,
  ) {}

  setToggle(key: keyof FeatureToggles, value: boolean) {
    this.featureToggleService.set(key, value);
  }

  resetOne(key: keyof FeatureToggles) {
    this.featureToggleService.set(key, featureToggles[key]);
  }

  resetAll() {
    this.featureToggleService.merge(featureToggles);
  }

  keypress($event: KeyboardEvent): void {
    if (matchesKey($event)) {
      this.dialogRef.close();
    }
  }
}

// @ts-strict-ignore
import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  asapScheduler,
  debounceTime,
  delay,
  filter,
  first,
  fromEvent,
  map,
  merge,
  of,
  scan,
  Subject,
  takeUntil,
} from 'rxjs';

import { AlertService } from '../shared/alert.service';
import { FeatureTogglesDialogComponent } from './feature-toggles-dialog.component';
import { accumulateCheatCode, matches, matchesCheatCode, matchesTag } from './feature-toggles.util';

/**
 * Clip through walls.
 * https://www.doomworld.com/pageofdoom/cheat.html
 */
const cheatcode = 'idclip';
const storageKey = 'fs-enable-dev-mode';

/**
 * Lets you edit feature toggles states dynamically within the app. To enable,
 * add {@link cheatcode the cheat code} as a query parameter to the URL.
 */
@Component({
  selector: 'app-feature-toggles',
  styles: `
    :host {
      display: contents;
    }
  `,
  template: `<ng-content></ng-content>`,
})
export class FeatureTogglesComponent implements AfterViewInit, OnDestroy {
  readonly destroyed$ = new Subject<void>();
  readonly keypress$ = fromEvent(document, 'keypress', { capture: true }).pipe(
    map(event => (matches(event) ? (event as KeyboardEvent) : null)),
    filter(event => event != null),
    debounceTime(0, asapScheduler),
  );

  readonly cheatCode$ = fromEvent(document, 'keydown', { capture: true }).pipe(
    map(event => (matchesTag(event) ? (event as KeyboardEvent).code : null)),
    scan(accumulateCheatCode, [] as string[]),
    filter(matchesCheatCode),
  );

  readonly previouslyEnabled$ = merge(
    // Previously-stored values
    of(window.localStorage.getItem(storageKey) === 'true'),
  ).pipe(filter(value => value));

  readonly newlyEnabled$ = merge(
    // Values stored by other tabs
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => event.storageArea === window.localStorage && event.key === storageKey),
      map(event => event.newValue === 'true'),
    ),

    // Cheat code entered in URL
    this.route.queryParams.pipe(map(params => params[cheatcode] !== undefined)),

    // Cheat code entered by keyboard
    this.cheatCode$.pipe(map(() => true)),
  ).pipe(filter(value => value));

  readonly enabled$ = merge(this.previouslyEnabled$, this.newlyEnabled$);

  dialogRef: MatDialogRef<FeatureTogglesDialogComponent, void> | null = null;

  constructor(
    readonly dialog: MatDialog,
    readonly elementRef: ElementRef<HTMLElement>,
    readonly route: ActivatedRoute,
    readonly alertService: AlertService,
  ) {}

  ngAfterViewInit() {
    this.newlyEnabled$.pipe(first()).subscribe(enabled => {
      this.alertService.showSuccess('Dev tools enabled. Press backtick (`) to open.');
      window.localStorage.setItem(storageKey, 'true');
    });
    this.enabled$.pipe(first(), delay(0)).subscribe(enable => {
      this.enable();
    });
  }

  enable() {
    this.keypress$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.toggleDialog());
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  toggleDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.dialogRef = this.dialog.open(FeatureTogglesDialogComponent, { panelClass: 'fs-next' });
      this.dialogRef.afterClosed().subscribe(result => (this.dialogRef = null));
    }
  }
}

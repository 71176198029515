import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subject, distinctUntilChanged, startWith, switchMap, takeUntil } from 'rxjs';
import { FeatureToggles } from './feature-toggles';
import { FeatureToggleService } from './feature-toggles.service';

/**
 * Like ngIf, but is automatically enabled based on a named {@link FeatureToggles feature toggle}.
 *
 * For example, this button will only render if `'enableFooButton'` is true:
 *
 * ```html
 * <button *appFeatureToggle="enableFooButton">Foo</button>
 * ```
 *
 * @see {@link FeatureToggles}
 */
@Directive({
  selector: '[appFeatureToggle]',
})
export class FeatureToggleDirective implements OnInit, OnDestroy {
  readonly destroyed$ = new Subject<void>();
  readonly key$ = new ReplaySubject<keyof FeatureToggles>(1);
  readonly enabled$ = this.key$.pipe(
    switchMap(key => this.featureToggleService.get$(key)),
    startWith(false),
    distinctUntilChanged(),
  );

  @Input() set appFeatureToggle(key: keyof FeatureToggles) {
    this.key$.next(key);
  }

  constructor(
    readonly templateRef: TemplateRef<unknown>,
    readonly viewContainer: ViewContainerRef,
    readonly featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.enabled$.pipe(takeUntil(this.destroyed$)).subscribe(enabled => {
      if (enabled) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}

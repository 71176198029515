import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../ui/material.module';
import { FeatureToggleDirective } from './feature-toggle.directive';
import { FeatureTogglesDialogComponent } from './feature-toggles-dialog.component';
import { FeatureTogglesComponent } from './feature-toggles.component';
import { ButtonComponent } from '../fs-next/button/button.component';

@NgModule({
  declarations: [FeatureToggleDirective, FeatureTogglesDialogComponent, FeatureTogglesComponent],
  imports: [CommonModule, MaterialModule, ButtonComponent],
  exports: [FeatureToggleDirective, FeatureTogglesComponent],
})
export class FeatureTogglesModule {}

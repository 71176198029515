<h1 mat-dialog-title>Feature toggles</h1>
<div mat-dialog-content>
  <ul>
    @for (toggle of toggles$ | async; track toggle.key) {
      <li>
        <mat-checkbox [checked]="toggle.value" (change)="setToggle(toggle.key, $event.checked)">{{ toggle.key }}</mat-checkbox>
        <button fsn-button class="reset" aria-label="Reset" appearance="link" size="tiny" [disabled]="!toggle.changed" (click)="resetOne(toggle.key)">↩</button>
      </li>
    }
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (keypress)="keypress($event)" (click)="resetAll()">Reset all</button>
  <button mat-button mat-dialog-close (keypress)="keypress($event)" cdkFocusInitial>Close</button>
</div>
